import React, { Fragment,useEffect,useState } from "react";
import Header from "../components/header";
import Hero from "../components/hero";
import About from "../components/about";
import ServiceSection from "../components/servicesection";
import Tour from "../components/tour";
import Partners from "../components/partners";
import ProjectSection from "../components/projectsection";
import Testimonial from "../components/testimonial";
import BlogSection from "../components/blogsection";
import Footer from "../components/footer";
import PopupForm from "./popupForm.js"
const HomePage = () => {
  const [cookieDeleted, setCookieDeleted] = useState(false);
  
  useEffect(()=>{
    window.scrollTo(0,0)
  })

  const handleCookieDeleted = () => {
    setCookieDeleted(true);
  };

  return (
    <Fragment>
      <Header onCookieDeleted={handleCookieDeleted} />
      <Hero />
      <About />
      <PopupForm cookieDeleted={cookieDeleted} setCookieDeleted={setCookieDeleted}/>
      <ServiceSection />
      <Tour />
      <Partners />
      <ProjectSection />
      <Testimonial />
      <BlogSection />
      <Footer />
    </Fragment>
  );
};
export default HomePage;