import React from "react";
import { Link } from "react-router-dom";

import ContactForm from "./contactform";
// import "./style.css";
import bck from "../img/bedroom-3.jpg"
const Contactpage = () => {
  return (
    <section className="contact-page-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="contact-form" style={{backgroundImage:`url(${bck})`,backgroundRepeat:"no-repeat",backgroundSize:"cover",backgroundAttachment:"scroll"}}>
              <div className="site-heading" data-aos="fade-up">
                <h3 className="sub-title">Get Quote</h3>
                <h2 className="section-title">Free Consultancy</h2>
              </div>
              <ContactForm />
            </div>
          </div>
          <div className="col-lg-5 col-sm-6">
            <div className="contact-page-left">
              <h3 style={{marginLeft:"70px"}}> # Contact information</h3>
              <div className="contact-info">
                <div className="single-contact-info" data-aos="fade-up">
                  <div className="contact-info-icon">
                    <span className="fa fa-map-marker"/>
                  </div>
                  <div className="contact-info-text" style={{"width":"100%"}}>
                    <h5>Head office</h5>
                    <p>
                      Office - Palash, Jankalyan Nagar, Malad (W), Mumbai - 400 095;
                    </p>
                  </div>
                </div>
                <div className="single-contact-info" data-aos="fade-up">
                  <div className="contact-info-icon">
                  <Link to="/"  onClick={()=>{
                      let a = document.createElement('a')
                      a.href="tel:+919090193131"
                      a.click()
                    }}>
                      <span className="fa fa-phone" />
                    </Link>
                  </div>
                  <div className="contact-info-text" style={{"width":"100%"}}>
                    <h5>Phone No</h5>
                    <p> +91 90901 93131</p>
                  </div>
                </div>
                <div className="single-contact-info" data-aos="fade-up">
                  <div className="contact-info-icon">
                    <Link to="/"  onClick={()=>{
                      let a = document.createElement('a')
                      a.href="https://api.whatsapp.com/send?phone=919090193131"
                      a.click()
                    }}>
                      <span className="fa fa-whatsapp" />
                    </Link>
                  </div>
                  <div className="contact-info-text" style={{"width":"100%"}}>
                    <h5>WhatsApp Phone No</h5>
                    <p> +91 90901 93131</p>
                  </div>
                </div>
                <div className="single-contact-info" data-aos="fade-up">
                  <div className="contact-info-icon">
                    <span className="fa fa-envelope" />
                  </div>
                  <div className="contact-info-text" style={{"width":"100%"}}>
                    <h5 style={{marginLeft:"20px"}}>Email</h5>
                    <p>sales@gowdarealty.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-sm-6">
            <div className="contact-page-right">
              <iframe
                title="map"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=B,%20SAHAWAS%20BUILDING,%20Jankalyan%20Nagar%20Rd,%20Malad,%20Jankalyan%20Nagar,%20Malad%20West,%20Mumbai,%20Maharashtra%20400095+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contactpage;
