import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import close from '../img/close.svg'
const PopupForm = ({ cookieDeleted,setCookieDeleted }) => {
    const [visible, setVisible] = useState(false);
    const [flag, setFlag] = useState(0);
    useEffect(() => {
        // Check if the cookie exists
        if (Cookies.get('formSubmitted') === 'true') {
            setFlag(1);
        }
        const interval = setInterval(openForm2, 10000);
        return () => clearInterval(interval);
    }, [flag]);

    useEffect(() => {
        if (cookieDeleted) {
            setVisible(true);
        }
    }, [cookieDeleted]);

    const openForm = () => {
        setVisible(!visible);
    };

    const openForm2 = () => {
        if (!visible && flag === 1) {
            // do nothing
        } else {
            setVisible(true);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const name = event.target.name.value;
        const email = event.target.email.value;
        const mobile = event.target.mobile.value;
        const desc = event.target.desc.value;

        try {

            const response = await axios.post('https://gowdarealty.com/admin/controller/process_add_user.php', {
                name,
                email,
                mobile,
                desc
            });
            if (response.status === 200) {
                console.log('Form submitted successfully');
                setVisible(false);
                setCookieDeleted(false);
                setFlag(1);
                // Set a cookie to indicate the form has been submitted
                Cookies.set('formSubmitted', 'true', { expires: 30 , path: '/'}); // Expires in 30 days
                window.location.href = '/thankyou.php';
            }
        } catch (error) {
            console.error('Form submission failed', error);
        }
    };

    return (
        <div>
            {visible && (
                <div className="fixed-form">
                <button onClick={openForm} style={{"position":"fixed","display":"flex",
                                                "justifyContent":"center",
                                                "paddingTop":"10px",
                                                "alignItem":"center",
                                                "z-index":"998",
                                                "right":"20px",
                                                "top":"20px",
                                                "width":"30px",
                                                "height":"30px",
                                                "borderRadius":"5%",
                                                "backgroundColor": "#d8a921"
                                            }}><img src={close}/></button>
                    <form id="inlineForm" onSubmit={handleSubmit}>
                    <h3 className="hh5" style={{"color":"black"}}>Enquiry</h3>
                        <input type="text" id="name" name="name" placeholder="Name" required />
                        <input type="email" id="email" name="email" placeholder="Email" required />
                        <input type="text" id="mobile" name="mobile" placeholder="Mobile" required />
                        <textarea id="desc" name="desc" placeholder="Query" style={{"height":"80px"}} required></textarea>
                        <button type="submit">Submit</button>
                    </form>
                    <button onClick={()=>{
                      let a = document.createElement('a')
                      a.href="tel:+919090193131"
                      a.click()
                    }} style={{"position":"fixed","display":"flex",
                                                "justifyContent":"center",
                                                "paddingTop":"10px",
                                                "alignItem":"center",
                                                "z-index":"998",
                                                "left":"20px",
                                                "bottom":"20px",
                                                "width":"40px",
                                                "height":"40px",
                                                "borderRadius":"5%",
                                                "backgroundColor": "#007bff"
                                            }}><i className="fa fa-phone" style={{fontSize:"25px"}}/></button>
                    <button onClick={()=>{
                      let a = document.createElement('a')
                      a.href="https://api.whatsapp.com/send?phone=919090193131"
                      a.click()
                    }} style={{"position":"fixed","display":"flex",
                        "justifyContent":"center",
                        "paddingTop":"10px",
                        "alignItem":"center",
                        "z-index":"998",
                        "right":"20px",
                        "bottom":"20px",
                        "width":"40px",
                        "height":"40px",
                        "borderRadius":"5%",
                        "backgroundColor": "#25D366"
                    }}><i className="fa fa-whatsapp" style={{fontSize:"25px"}}/></button>
                </div>
            )}
        </div>
    );
};

export default PopupForm;