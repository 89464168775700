import React from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import Img1 from "../img/building-14.jpg";
import Img2 from "../img/plan.png";
import Img3 from "../img/24-hours.png";
import Img4 from "../img/group.png";
import Img5 from "../img/best-price.png";
import Img6 from "../img/bedroom-6.jpg";
import Img7 from "../img/hallroom-7.jpg";
import Img8 from "../img/kitchen-11.jpg";

// import "./style.css";

const ProjectSingle13 = () => {
  return (
    <section className="project-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="project-details-top">
              <div className="project-details-top-img">
                <img src={Img1} alt="img" />
              </div>
              <div className="project-details-top-box">
                <h3>Project Details</h3>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Project</h5>
                      <p>Interior</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Location</h5>
                      <p>Andheri</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Year</h5>
                      <p>2021</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Type</h5>
                      <p>Residential</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-details-top-text">
              <h2>Architectural Elegance</h2>
              <p>
                Vitthal Darshan stands as a testament to timeless architectural design, combining elements of traditional Indian aesthetics with contemporary features. The building's façade is adorned with intricate carvings and motifs that pay homage to the cultural heritage of the region.              </p>
              <p>
                <h3> - Spectacular Sky Garden - </h3>
                One of the standout features of Vitthal Darshan is its lush sky garden located on the rooftop. Residents can escape the urban bustle and enjoy panoramic views of Andheri while strolling through beautifully landscaped pathways, relaxing in shaded seating areas, and even practicing yoga in the designated wellness corner.              </p>
              <p>
                <h3> -Vibrant Community Hub - </h3>
                The building's central courtyard is designed as a vibrant community hub. It hosts regular cultural events, art exhibitions, and performances, fostering a sense of togetherness among the residents and creating a dynamic living environment.              </p>
              <p>
                <h3> - Convenient Connectivity - </h3>
                Situated in the heart of Andheri, Vitthal Darshan offers easy access to major transportation hubs, shopping districts, and cultural landmarks. The building is surrounded by well-maintained walkways and cycle paths, encouraging residents to explore the neighborhood at their leisure.              </p>
            </div>
            <div className="project-overview">
              <div className="row">
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img2} alt="img" />
                    <h5>Project size</h5>
                    <p>59000 sq ft</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img3} alt="img" />
                    <h5>Project time</h5>
                    <p>4 Years</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img4} alt="img" />
                    <h5>People worked</h5>
                    <p>210</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img5} alt="img" />
                    <h5>Project cost</h5>
                    <p>₹ 295cr</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-details-type">
              <div className="row">
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img6} alt="img" />
                      <p>BEDROOM</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                        Indulge in comfort and tranquility within your Vitthal Darshan bedroom. Carefully designed for a blend of relaxation and style, our bedrooms offer the ultimate retreat.                      </p>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img7} alt="img" />
                      <p>HALLROOM</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                        Discover the heart of Vitthal Darshan – our adaptable and welcoming living spaces. Our halls or living rooms are designed to be the nucleus of daily life and special moments.                      </p>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img8} alt="img" />
                      <p>KITCHEN</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                        Experience culinary excellence in your meticulously designed Vitthal Darshan kitchen. Our kitchens unite functionality and aesthetics, inspiring your culinary creativity.                      </p>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ProjectSingle13;
