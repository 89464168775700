import React from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import Img1 from "../img/project-sf-9.jpg";
import Img2 from "../img/plan.png";
import Img3 from "../img/24-hours.png";
import Img4 from "../img/group.png";
import Img5 from "../img/best-price.png";
import Img6 from "../img/bedroom-9.jpg";
import Img7 from "../img/hallroom-6.jpg";
import Img8 from "../img/kitchen-9.jpg";

// import "./style.css";

const ProjectSingle9 = () => {
  return (
    <section className="project-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="project-details-top">
              <div className="project-details-top-img">
                <img src={Img1} alt="img" />
              </div>
              <div className="project-details-top-box">
                <h3>Project Details</h3>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Project</h5>
                      <p>Interior</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Location</h5>
                      <p>CHEMBUR</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Year</h5>
                      <p>2017</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Type</h5>
                      <p>Residential</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-details-top-text">
              <h2>Best Interior Ideas</h2>
              <p>
                Ratnadeep Towers is a beacon of elegance and modern living nestled in the heart of Chembur, Mumbai. This prestigious residential gem redefines urban luxury, providing residents with a harmonious blend of comfort, convenience, and sophistication.
              </p>
              <p>
                <h3> - Architectural Splendor - </h3>
                Ratnadeep Towers is a marvel of architectural finesse, seamlessly integrating contemporary design with functionality, creating a striking yet practical structure:
              </p>
              <p>
                <h3> - Conclusion - </h3>

                Experience the epitome of luxury living at Ratnadeep Towers. It's more than a residence; it's a lifestyle choice where every detail is designed to provide you with comfort, elegance, and a vibrant community. Embrace the Ratnadeep way of life, where every day feels like a celebration of modern living in the heart of Chembur.
              </p>
            </div>
            <div className="project-overview">
              <div className="row">
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img2} alt="img" />
                    <h5>Project size</h5>
                    <p>42000 sq ft</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img3} alt="img" />
                    <h5>Project time</h5>
                    <p>3.5 Years</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img4} alt="img" />
                    <h5>People worked</h5>
                    <p>198</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img5} alt="img" />
                    <h5>Project cost</h5>
                    <p>₹ 195cr</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-details-type">
              <div className="row">
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img6} alt="img" />
                      <p>BEDROOM</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                       Welcome to the epitome of comfort and serenity within Ratnadeep Residences. Our bedrooms are thoughtfully designed to provide the perfect blend of relaxation, style, and functionality.
                      </p>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img7} alt="img" />
                      <p>HALLROOM</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                        Experience the heart of Ratnadeep Residences – our versatile and welcoming living spaces. Our halls or living rooms are designed to be the focal point of your daily life and special gatherings.
                      </p>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img8} alt="img" />
                      <p>KITCHEN</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                        Discover culinary excellence in our meticulously designed kitchens. Ratnadeep's kitchens are a harmonious blend of functionality and aesthetics, inspiring your culinary creations.
                      </p>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ProjectSingle9;
