import React from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import Img1 from "../img/project-s-10.webp";
import Img2 from "../img/plan.png";
import Img3 from "../img/24-hours.png";
import Img4 from "../img/group.png";
import Img5 from "../img/best-price.png";
import Img6 from "../img/bedroom-10.jpg";
import Img7 from "../img/hallroom-5.jpg";
import Img8 from "../img/kitchen-10.jpg";

// import "./style.css";

const ProjectSingle10 = () => {
  return (
    <section className="project-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="project-details-top">
              <div className="project-details-top-img">
                <img src={Img1} alt="img" />
              </div>
              <div className="project-details-top-box">
                <h3>Project Details</h3>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Project</h5>
                      <p>Interior</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Location</h5>
                      <p>Andheri</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Year</h5>
                      <p>2019</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="project-details-top-box-text">
                      <h5>Type</h5>
                      <p>Residential</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-details-top-text">
              <h2>Best Interior Ideas</h2>
              <p>
                Ravikiran Towers is a stunning residential masterpiece situated in the heart of Andheri, one of Mumbai's most sought-after and vibrant neighborhoods. This prestigious building is designed to offer a perfect blend of modern luxury, convenience, and timeless elegance, providing residents with an exceptional living experience.
              </p>
              <p>
                <h3> - Convenience and Connectivity - </h3>
                Ravikiran Towers is strategically positioned to offer a perfect balance between urban convenience and peaceful living:
              </p>
              <p>
               <h3> - Community and Lifestyle - </h3>
                Ravikiran Towers isn't just a building; it's a community where residents can forge meaningful connections and embrace a high-quality lifestyle:
              </p>
              <p>
                <h3> - Conclusion - </h3>
                Experience the epitome of luxury living at Ravikiran Towers. It's more than a residence; it's a lifestyle choice where every detail is designed to provide you with comfort, elegance, and a vibrant community. Embrace the Ravikiran way of life, where every day feels like a celebration of modern living in the heart of Andheri.
              </p>
            </div>
            <div className="project-overview">
              <div className="row">
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img2} alt="img" />
                    <h5>Project size</h5>
                    <p>52000 sq ft</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img3} alt="img" />
                    <h5>Project time</h5>
                    <p>4 Years</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img4} alt="img" />
                    <h5>People worked</h5>
                    <p>265</p>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="project-overview-box">
                    <img src={Img5} alt="img" />
                    <h5>Project cost</h5>
                    <p>₹ 275cr</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-details-type">
              <div className="row">
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img6} alt="img" />
                      <p>BEDROOM</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                        Experience the epitome of comfort and tranquility within Ravikiran Residences. Our bedrooms are thoughtfully designed to provide a harmonious blend of relaxation, style, and functionality.
                      </p>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img7} alt="img" />
                      <p>HALLROOM</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                        Discover the heart of Ravikiran Residences – our versatile and welcoming living spaces. Our halls or living rooms are designed to be the focal point of your daily life and special gatherings.
                      </p>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="details-box">
                    <div className="project-box project-details-box">
                      <img src={Img8} alt="img" />
                      <p>KITCHEN</p>
                    </div>
                    <div className="project-details-box-meta-text">
                      <p>
                        Discover culinary excellence in our meticulously designed kitchens. Ravikiran's kitchens are a harmonious blend of functionality and aesthetics, inspiring your culinary creations.
                      </p>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ProjectSingle10;
