import React, { Fragment,useEffect } from "react";
import Header from "../components/header";
import PageTitle from "../components/pagetitle";
import BlogSingle from "../components/blogsingle";
import Footer from "../components/footer";
import ScrollToTop from "react-scroll-to-top";
const ContactPage = () => {
    useEffect(()=>{window.scrollTo(0,0)})

  return (
    <Fragment>
      <Header />
      <PageTitle pageTitle={"BLOG DETAILS"} pagesub={"Blog Details"} />
      <BlogSingle />
      <Footer />
    </Fragment>
  );
};
export default ContactPage;