import React from "react";
import { Link } from "react-router-dom";

import Img1 from "../img/blog-1.png";
import Img2 from "../img/blog-2.png";
import Img3 from "../img/blog-3.png";
import Img4 from "../img/blog-4.png";
import Img5 from "../img/post-5.jpg";

// import "./style.css";

const Sidebar = () => {
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <div className="col-lg-4 col-md-5">
      
      <div className="blog-sidebar-categories" data-aos="fade-up">
        <div className="blog-sidebar-heading">
          <h3>Categories</h3>
        </div>
        <br/>
        <ul className="sidebar-content">
          <li>
            <Link to="/blog-single1">
              Buying Guide <span>01</span>
            </Link>
          </li>
          <li>
            <Link to="/blog-single2">
              Commercial <span>01</span>
            </Link>
          </li>
          <li>
            <Link to="/blog-single3">
              Lifestyle <span>01</span>
            </Link>
          </li>
          <li>
            <Link to="/blog-single4">
              Perspective <span>01</span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="blog-sidebar-post" data-aos="fade-up">
        <div className="blog-sidebar-heading">
          <h3>Popular Post</h3>
        </div>
        <div className="sidebar-post-box" data-aos="fade-up">
          <div className="sidebar-post-img">
            <img src={Img1} alt="img" />
          </div>
          <div className="sidebar-post-text">
            <Link to="/blog-single1">
              <h5>The Pros and Cons of Buying New Construction vs. Existing Homes</h5>
            </Link>
          </div>
        </div>
        <div className="sidebar-post-box">
          <div className="sidebar-post-img">
            <img src={Img2} alt="img" />
          </div>
          <div className="sidebar-post-text">
            <Link to="/blog-single2">
              <h5>The Impact of Location on Commercial Property Value</h5>
            </Link>
          </div>
        </div>
        <div className="sidebar-post-box">
          <div className="sidebar-post-img">
            <img src={Img3} alt="img" />
          </div>
          <div className="sidebar-post-text">
            <Link to="/blog-single3">
              <h5>The Benefits of Owning a Vacation Home: A Lifestyle Perspective</h5>
            </Link>
          </div>
        </div>     
        <div className="sidebar-post-box">
          <div className="sidebar-post-img">
            <img src={Img4} alt="img" />
          </div>
          <div className="sidebar-post-text">
            <Link to="/blog-single4">
              <h5>The Future of Real Estate: Trends and Predictions for the Next Decade</h5>
            </Link>
          </div>
        </div>    
      </div>
    </div>
  );
};

export default Sidebar;
