import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "./sidebar";

import blog1 from "../img/blog-1.png";
import blog2 from "../img/blog-2.png";
import blog3 from "../img/blog-3.png";
import blog4 from "../img/blog-4.png";

// import "./style.css";

const BlogList = () => {
  return (
    <section className="blog-page-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-7">
            <div className="blog-left">
              <div className="row">
                <div className="col-lg-12">
                  <Link
                    to="/blog-single1"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog1} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>The Pros and Cons of Buying New Construction vs. Existing Homes</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/blog-single2"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog2} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>The Impact of Location on Commercial Property Value</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/blog-single3"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog3} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>The Benefits of Owning a Vacation Home: A Lifestyle Perspective</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-12">
                  <Link
                    to="/blog-single4"
                    className="news-box"
                    data-aos="fade-up"
                  >
                    <div className="news-img">
                      <img src={blog3} alt="img" />
                    </div>
                    <div className="news-text">
                      <h3>The Future of Real Estate: Trends and Predictions for the Next Decade</h3>
                    </div>
                  </Link>
                </div>
              </div>
              
            </div>
          </div>
          <Sidebar />
        </div>
      </div>
    </section>
  );
};

export default BlogList;